<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam.orderNo" allowClear></a-input>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托单位名称">
                <a-input v-model="queryParam.customerName" allowClear></a-input>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="订单类型">
                <a-select
                  allowClear
                  style="width: 100%"
                  v-model="queryParam.order_type"
                >
                  <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="部门">
                <a-select
                  showSearch
                  placeholder="请选择部门"
                  style="width: 100%"
                  v-model="queryParam.department"
                >
                  <a-select-option v-for="op in GLOBAL.finDepartment" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" @click="$refs.table.refresh(true)" v-perms="'KBM_ORDER_QUERY'">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate" v-perms="'KBM_ORDER_EDIT'">新建</a-button>
        <a-button type="danger" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length" v-perms="'KBM_ORDER_EDIT'">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <span slot="orderTypeSlot" slot-scope="text">
          <a-tag :color="text === 2? 'red':'green'">{{ text === 2? '应付':'应收' }}</a-tag>
        </span>
        <span slot="businessDateSlot" slot-scope="text, record">
          {{ record.business_date ? record.business_date : '' }}~
          {{ record.business_end_date ? record.business_end_date : '' }}
        </span>
        <span slot="orderFeeInfosSlot" slot-scope="text">
          <div v-for="item in text" :key="item.id" :class="feeStatusColor(item.fee_status)">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <span>{{ GLOBAL.finDepartment[item.department].name }}/{{ item.fee_name }}/{{ item.amount }}</span>
          </div>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)" v-perms="'KBM_ORDER_EDIT'">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)" v-perms="'KBM_ORDER_EDIT'">删除</a>
            <a-divider type="vertical" />
            <a @click="handleFmsAudit(record)" v-perms="'KBM_ORDER_APPLY'" v-if="record.order_type === 2">商务审核</a>
            <a @click="handleFmsApply(record)" v-perms="'KBM_ORDER_APPLY'" v-if="record.order_type === 1">开票申请</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="formVisible"
        :confirmLoading="confirmLoading"
        :title="modalFlag === 'create' ? '创建业务登记' : '编辑业务登记'"
        :width="720"
        @cancel="formVisible = false"
        @ok="handleSubmit"
      >
        <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
          <a-row :gutter="[20, 10]">
            <a-col :span="12">
              <a-form-model-item label="订单类型" prop="tenant">
                <a-select
                  allowClear
                  v-model="form.order_type"
                  style="width: 100%"
                  @change="clearCustomerInfo"
                >
                  <a-select-option v-for="op in orderTypeOps" :key="op.value" :value="op.value">{{
                    op.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="委托单位" prop="customer_name">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  ref="customer_name"
                  placeholder="请选择委托单位"
                  style="width: 300px"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="业务类型">
                <a-select
                  allowClear
                  v-model="form.business_name"
                  style="width: 100%"
                >
                  <a-select-option v-for="op in businessNameOps" :key="op.value" :value="op.value">{{
                    op.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="业务日期">
                <a-date-picker v-model="form.business_date" class="full-width" format="YYYY-MM-DD" @change="onDateBeginChange"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="业务结束日期">
                <a-date-picker v-model="form.business_end_date" class="full-width" format="YYYY-MM-DD" @change="onDateEndChange"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注">
                <a-textarea v-model.trim="form.remark" />
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">费用信息</a-divider>
            <a-col :span="24">
              <edit-table :columns="feeColumns" :sourceData="feeData" @getTableDate="getFeeData"></edit-table>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
      <a-modal
        :visible="applyFormVisible"
        :confirmLoading="applyFormLoading"
        title="商务审核申请"
        :width="720"
        @cancel="handleApplyFormCancel"
        @ok="handleApplyFormSubmit"
      >
        <a-form-model layout="vertical" :model="applyForm" :rules="applyFormRules" ref="applyForm">
          <a-divider orientation="left">基础信息：{{ applyForm.order_no }}-{{ applyForm.customer_name }}</a-divider>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="付款方式" prop="pay_method">
                <a-select v-model="applyForm.pay_method" @change="payMethodToRemark">
                  <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="是否预付" prop="is_advance">
                <a-select v-model="applyForm.is_advance">
                  <a-select-option :value="item.value" v-for="item in isActiveOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="附件数" prop="appendix_num">
                <a-input suffix="张" v-model="applyForm.appendix_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="发票号码" prop="invoice_number">
            <a-select
              showSearch
              mode="multiple"
              ref="invoice_number"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择发票号码"
              style="width: 100%"
              v-model="applyForm.invoice_num"
            >
              <a-select-option v-for="op in invoiceRegOps" :key="op.id"
              >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="合同号" prop="contract_list">
            <a-select
              showSearch
              mode="multiple"
              ref="contract_list"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择合同号"
              style="width: 100%"
              v-model="applyForm.contract_list"
            >
              <a-select-option v-for="op in leaseInfoList" :key="op.id">
                合同号:{{ op.contract_no }}
                <span v-if="op.is_no_term===0 && !op.contract_termination_date">/ 截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
                <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
                / {{ op.customer_info.customer_cn_name }}
                <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-divider orientation="left">费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ applyForm.totalAmount }}</a-tag>
          </a-divider>
          <a-form-model-item>
            <a-table :columns="feeInfoColumns" :data-source="feeInfoData" :pagination="false"></a-table>
          </a-form-model-item>
          <a-form-model-item label="申请备注" prop="apply_remark">
            <a-textarea v-model="applyForm.apply_remark"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable, EditTable } from '@/components'
import moment from 'moment'
import { queryCompositePages, removeCompositeOrder, saveUpdateCompositeOrder, getCompositeDetailById, mmsBusinessAuditPayment } from '@/api/mms'
import { getCommonOptions, getCustomer, getSupplierAndByName, getCommonNoPage, getLeaseInfoListByDepartment } from '@/api/common'
import debounce from 'lodash/debounce'
import { getFirstLetter } from '@/utils/util'
import { getchargeTax } from '@/api/fms'
export default {
  components: {
    STable,
    EditTable
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {},
      orderTypeOps: [{ name: '应收', value: 1 }, { name: '应付', value: 2 }],
      businessNameOps: [{ name: '服务费', value: '服务费' }],
      columns: [
        {
          title: '业务编号',
          dataIndex: 'order_no'
        },
        {
          title: '订单类型',
          dataIndex: 'order_type',
          scopedSlots: { customRender: 'orderTypeSlot' }
        },
        {
          title: '委托单位名称',
          dataIndex: 'customer_name'
        },
        {
          title: '业务日期',
          dataIndex: 'business_date',
          scopedSlots: { customRender: 'businessDateSlot' }
        },
        {
          title: '总金额',
          dataIndex: 'total_amount'
        },
        {
          title: '费用信息',
          dataIndex: 'order_fee_infos',
          scopedSlots: { customRender: 'orderFeeInfosSlot' }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      feeColumns: [
        {
          title: '部门',
          dataIndex: 'department',
          width: 120,
          extra: {
            type: 'select',
            options: this.GLOBAL.finDepartment,
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        {
          title: '费用',
          dataIndex: 'fee_type_id',
          width: 120,
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 9, type: 1 }],
            options: [],
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                disabled={!(record.fee_status === 1 ||
                record.fee_status === 4 ||
                !record.fee_status)}
                min={0}
                onChange={val => {
                  this.feeData[index]['amount'] = val
                }}
              />
            )
          }
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                disabled={!(record.fee_status === 1 ||
                record.fee_status === 4 ||
                !record.fee_status)}
                min={0}
                onChange={val => {
                  if (val !== 0 && val > 1) {
                    this.feeData[index]['tax_rate'] = val / 100
                  } else {
                    this.feeData[index]['tax_rate'] = val
                  }
                }}
              />
            )
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return queryCompositePages(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      customerInfo: undefined,
      fetching: false,
      agencyOps: [],
      modalFlag: 'create',
      formVisible: false,
      confirmLoading: false,
      inputVisible: false,
      inputValue: '',
      form: {
        id: null,
        order_type: 2,
        customer_id: null,
        customer_name: null,
        business_date: null,
        business_end_date: null,
        remark: null,
        order_fee_infos: []
      },
      taxRateOps: [],
      rules: {
        customer_name: [{ required: true, message: '请选择委托单位', trigger: 'blur' }]
      },
      feeData: [],
      applyFormLoading: false,
      applyFormVisible: false,
      applyForm: {
        pay_method: '',
        apply_remark: '',
        appendix_num: null,
        invoice_num: [],
        is_advance: 1,
        contract_list: [],
        order_id: null,
        order_no: null,
        customer_name: null,
        totalAmount: 0
      },
      applyFormRules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        },
        {
          value: 7, name: '免付款'
        }
      ],
      isActiveOps: [
        {
          value: 1, name: '非预付'
        },
        {
          value: 2, name: '预付'
        }
      ],
      invoiceRegOps: [],
      leaseInfoList: [],
      feeInfoColumns: [
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.finDepartment[text].name : ''
          }
        },
        {
          title: '费用',
          dataIndex: 'fee_name'
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '税率',
          dataIndex: 'amount'
        },
        {
          title: '审核状态',
          dataIndex: 'fee_status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        }
      ],
      feeInfoData: []
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
  },
  methods: {
    moment,
    getCommonOptions,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    findOrderType(text) {
      console.info(this.orderTypeOps)
      this.orderTypeOps.forEach(item => {
        console.info(item)
        console.info(text)
        if (item.value === text) {
          console.info(item.name)
          return item.name
        }
      })
    },
    onDateBeginChange(date, dateString) {
      if (date) {
        const startDate = date.format('YYYY-MM-DD');
        this.form.business_date = startDate
      } else {
        this.form.business_date = null
      }
    },
    onDateEndChange(date, dateString) {
      if (date) {
        const startDate = date.format('YYYY-MM-DD');
        this.form.business_end_date = startDate
      } else {
        this.form.business_end_date = null
      }
    },
    clearCustomerInfo() {
      this.customerInfo = undefined
      this.agencyOps = []
      this.form.customer_id = null
      this.form.customer_name = null
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      let queryDate = {
        supplierName: value,
        excludeBlack: 'Y'
      }
      let queryCustomerfunction = getSupplierAndByName
      if (this.form.order_type === 1) {
        queryCustomerfunction = getCustomer
        queryDate = {
          customerName: value,
          excludeBlack: 'Y'
        }
      }
      queryCustomerfunction(queryDate).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form.customer_id = value.key
      this.form.customer_name = value.label
      this.agencyOps = []
      this.fetching = false
    },
    getFeeData(data) {
      this.feeData = data
    },
    handleDelFeeType(index) {
      this.feeData.splice(index, 1)
    },
    handleCreate() {
      this.modalFlag = 'create';
      this.formVisible = true;
      this.inputValue = '';
      this.inputVisible = false;
      this.customerInfo = undefined
      this.form = {
        id: null,
        order_type: 2,
        customer_id: null,
        customer_name: null,
        business_date: null,
        businessEndDate: null,
        remark: null,
        order_fee_infos: []
      };
      this.feeData = [];
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.inputValue = ''
      this.inputVisible = false
      getCompositeDetailById({ order_id: record.id }).then(res => {
        this.form = res
        this.feeData = res.order_fee_infos
        this.customerInfo = {
            key: res.customer_id,
            label: res.customer_name
          }
      })
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          removeCompositeOrder({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.order_fee_infos = this.feeData.map(b => {
            const v = { ...b }
            v.fee_status = v.fee_status || null
            return v
          })
          saveUpdateCompositeOrder(this.form)
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.formVisible = false
              this.$refs.table.refresh(true)
            }).finally(() => {
              this.confirmLoading = false
            })
        } else {
          return false
        }
      })
    },
    getFeeInfoTableDate(data) {
      this.feeInfoData = data;
    },
    handleFmsAudit(record) {
      for (let i = 0; i < record.order_fee_infos.length; i++) {
        if (record.order_fee_infos[i].fee_status !== 2 && record.order_fee_infos[i].fee_status !== 4) {
          this.$message.warning(this.GLOBAL.finDepartment[record.order_fee_infos[i].department].name +
            '的费用状态必须为业务已审核或商务审核被驳回')
          return
        }
      }
      getCommonNoPage('invoice_registration', {}).then((v) => {
        this.invoiceRegOps = v
      })
      getLeaseInfoListByDepartment({ 'customer_id_list': [record.customer_id] }).then(v => {
        this.leaseInfoList = v
      })
      this.applyForm = {
        order_id: record.id,
        order_no: record.order_no,
        customer_name: record.customer_name,
        totalAmount: record.total_amount,
        pay_method: '',
        apply_remark: '',
        appendix_num: null,
        invoice_num: [],
        is_advance: 1,
        contract_list: []
      }
      this.feeInfoData = record.order_fee_infos
      this.applyFormVisible = true
    },
    handleApplyFormCancel() {
      this.applyFormVisible = false
      this.feeInfoData = []
      this.applyForm = {
        order_id: null,
        order_no: null,
        customer_name: null,
        totalAmount: null,
        pay_method: '',
        apply_remark: '',
        appendix_num: null,
        invoice_num: [],
        is_advance: 1,
        contract_list: []
      }
    },
    handleApplyFormSubmit() {
      this.$refs.applyForm.validate(valid => {
        if (valid) {
          this.applyFormLoading = true;
          mmsBusinessAuditPayment(this.applyForm).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
            this.$refs.table.refresh(false);
            this.handleApplyFormCancel();
          }).finally(_ => {
            this.applyFormLoading = false;
          });
        }
      });
    },
    payMethodToRemark() {
      if (this.form.pay_method === 7) {
        this.form.apply_remark = '无需付款，对应成本为0，请调整账面成本'
      } else {
        this.form.apply_remark = '';
      }
    },
    handleFmsApply() {
      this.$notification['success']({
        message: '提示',
        description: '系统暂不支持开票申请'
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  }
}
</script>
